import * as React from 'react';
import { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { Helmet } from 'react-helmet';


const devices = {
	android: {
		title: 'Android',
		redirect: true,
		detectionMessage: (
			<>
				<p>You appear to be using an <b>Android</b> device. You can download our app from Google Play:</p>
			</>
		),
		genericMessage: (
			<>
				<p>
					Any smartphone other than an iPhone or tablet other than iPad is most likely an Android device.
					If that is your case, you can download Right News by clicking the Google Play link below.
				</p>
			</>
		),
		targetLink: 'https://play.google.com/store/apps/details?id=com.rovine.news',
		targetElement: (
			<img src='https://play.google.com/intl/en_us/badges/images/badge_new.png' alt='Google Play link' />
		)
	},
	ios: {
		title: 'iOS',
		redirect: true,
		detectionMessage: (
			<>
				<p>You appear to be using an <b>iOS</b> device. You can download our app from Apple's App Store:</p>
			</>
		),
		genericMessage: (
			<>
				<p>If your device is an iPhone or iPad, you can get Right News from Apple's App Store by clicking the link below .</p>
			</>
		),
		targetLink: 'https://itunes.apple.com/us/app/right-news-conservative/id1450313197',
		targetElement: (
			<img src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png' alt='App Store link' />
		)
	},
	web: {
		title: 'Web Version',
		redirect: false,
		detectionMessage: (
			<>
				<p>You appear to be using a <b>laptop or desktop</b>. You can use the web version of our app by clicking the image below.</p>
			</>
		),
		genericMessage: (
			<>
				<p>
					If you're using a laptop or desktop, you can access the app by clicking the link below.
					The web version of the app also works on smartphones and tablets, but the installable version is recommended.
				</p>
			</>
		),
		targetLink: '//news.rovinemedia.com',
		targetElement: (
			<img src='/images/right-news/right-news-web.svg' alt='Right News Web' />
		),
	},
	amazon: {
		title: 'Amazon',
		redirect: false,
		detectionMessage: (
			<>
				<p>You appear to be using an <b>Amazon</b> device. You can get our app from the Amazon App Store:</p>
			</>
		),
		genericMessage: (
			<>
				<p>We even have an Amazon version of our app. You can get it from the Amazon App Store.</p>
			</>
		),
		targetLink: 'http://www.amazon.com/gp/mas/dl/android?p=com.rovine.news',
		targetElement: (
			<img src='https://lh3.googleusercontent.com/dlbaCGBGwKX355y2lk5Zl5_GjR9dhx7R_EnSzusa0xuZZSFFmNZfSs7BJTwD_Ue81kxT0mTtUNhF8VotB6nZOZN2-JuZrmvNBsNldDx7QGP3m_gva5fweAMGcAc63xHvISW3qkLQkg=w171-h51-no' alt='Amazon App Store link' />
		)
	},
};


const getCurrentDevice  = (userAgent) => {
	if (/Silk/i.test(userAgent)) {
		return devices.amazon;
	}
	if (/android/i.test(userAgent)) {
		return devices.android;
	}
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return devices.ios;
	}
	return devices.web;
};


const SharePage = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	const [currentDevice, setCurrentDevice] = useState(devices.web);

	useEffect(
		() => {
			const userAgent = typeof window !== 'undefined' && window.navigator.userAgent;
			setCurrentDevice(getCurrentDevice(userAgent));
		},
		[]
	);

	const otherDevices = Object.keys(devices).map(
		key => devices[key]
	).filter(
		device => device !== currentDevice
	);
	

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="Right News App" />
			{currentDevice && currentDevice.redirect && <Helmet><meta http-equiv="refresh" content={"0; url=" + currentDevice.targetLink} /></Helmet>}
			<h1>The Right News App</h1>
			<h2>{currentDevice.title}</h2>
			{currentDevice.detectionMessage}
			<a href={currentDevice.targetLink} target="_blank" rel="noreferrer">
				{currentDevice.targetElement}
			</a>
			{
				otherDevices.map(
					device => (
						<div key={device.title}>
							<h2>{device.title}</h2>
							{device.genericMessage}
							<a href={device.targetLink} target="_blank" rel="noreferrer">
								{device.targetElement}
							</a>
						</div>
					)
				)
			}
		</Layout>
	);
}


export default SharePage;


export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

